/* eslint-disable no-eval */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Typography } from '@mui/material';
import clsx from 'clsx';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import KababRender from './KababRender';
const Chips = (props) => {
    const [isKababOpen, setIsKababOpen] = useState(null);
    const { kababOptions, label, classes, sets, questionsPerSet, examPrice, examID, setID, publishStatus, to, isLearning, } = props;
    const vertClickHandler = (event) => {
        setIsKababOpen(event.currentTarget);
    };
    const handleClose = () => {
        setIsKababOpen(null);
    };
    return (React.createElement("div", { className: classes.chipMain },
        React.createElement(Link, { to: eval(`\`${to}\``), className: classes.navLink },
            React.createElement("div", { className: classes.chip },
                React.createElement(Typography, { className: classes.chipTitle }, label),
                examPrice !== null && examPrice !== undefined && (React.createElement(Typography, { className: clsx(classes.chipTopRight), variant: "h3" },
                    "\u20B9",
                    ` ${examPrice}`)),
                sets !== null && sets !== undefined && (React.createElement(Typography, { className: classes.chipBottom, variant: "body2" }, `Sets: ${sets} | Questions each set: ${questionsPerSet}`)),
                isLearning && (React.createElement(Typography, { className: clsx(classes.learningTypo, classes.chipRightBottom) }, "Learning Set")),
                publishStatus && (React.createElement(Typography, { className: clsx(classes.chipRightBottom, classes.publish), variant: "caption" }, "Publish")))),
        React.createElement(MoreVertIcon, { onClick: vertClickHandler, "aria-controls": "kabab-menu", "aria-haspopup": "true", "aria-label": "more" }),
        isKababOpen && (React.createElement(KababRender, { open: !!isKababOpen, anchorEl: isKababOpen, handleClose: handleClose, examID: examID, setID: setID, kababOptions: kababOptions, publishStatus: publishStatus }))));
};
export default Chips;
