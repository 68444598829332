import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import { clsx } from 'clsx';
import React, { useEffect, useState } from 'react';
import { useStyle } from '../../styles/style';
import { useQuestionsByExamSetId } from '../../utils/hookUtils';
import ExamLearningQuestion from './ExamLearningQuestion';
const ExamLearning = ({ exam, examSet }) => {
    const classes = useStyle();
    const [tab, setTab] = useState('1');
    const [values, setValues] = useState({
        questionDescription: '',
        choices: [{ choice: '' }],
    });
    const questions = useQuestionsByExamSetId(examSet.examSetID);
    useEffect(() => {
        if (questions.length && questions[Number(tab) - 1]) {
            const question = questions[Number(tab) - 1];
            setValues({
                questionDescription: question.questionDescription,
                choices: question.choices.map((choice) => ({
                    choice: choice.choice,
                })),
            });
        }
    }, [questions, tab]);
    const handleChange = (event, newValue) => {
        setTab(newValue);
        if (questions.length && questions[newValue - 1]) {
            const question = questions[newValue - 1];
            setValues({
                questionDescription: question.questionDescription,
                choices: question.choices.map((choice) => ({
                    choice: choice.choice,
                })),
            });
        }
        else {
            setValues({
                questionDescription: '',
                choices: [{ choice: '' }],
            });
        }
    };
    const tabArray = [];
    const tabPanel = [];
    for (let i = 1; i <= exam.noOfQuestionsPerSet; i++) {
        tabArray.push(React.createElement(Tab, { value: String(i), label: i, key: i, style: {
                minWidth: '1rem',
                background: '#00a2ed',
                color: 'white',
                borderRadius: '50%',
                margin: '0.7rem 0.2rem',
                height: '2rem',
                minHeight: '2rem',
            } }));
        tabPanel.push(React.createElement(TabPanel, { value: String(i), key: i, className: clsx(classes.tabContext, classes.scrollDefault) },
            React.createElement(ExamLearningQuestion, { index: i, exam: exam, defaultValues: values, examSet: examSet, access: exam.publishStatus })));
    }
    return (React.createElement("div", null,
        React.createElement(TabContext, { value: tab },
            React.createElement(Box, { sx: { borderBottom: 0.5, borderColor: 'divider' } },
                React.createElement(TabList, { value: tab, onChange: handleChange, "aria-label": "scrollable force tabs example", variant: "scrollable", scrollButtons: "auto", classes: { root: classes.questionTab } }, tabArray)),
            tabPanel)));
};
export default ExamLearning;
