import React from 'react';
import { useParams } from 'react-router-dom';
import { useStyle } from '../../styles/style';
import { useGetExamById, useGetExamSetByExamId, useQuestionsByExamSetId, } from '../../utils/hookUtils';
import ExamQuestionTab from '../ExamQuestion/ExamQuestionTab';
import ExamSetHeader from './ExamSetHeader';
import QuestionExcelButton from '../ExamQuestion/QuestionExcelButton';
import ExamLearning from '../ExamLearning';
const ExamSetQuestion = () => {
    const classes = useStyle();
    const { examID, setID } = useParams();
    if (!examID)
        throw Error('ExamID not found');
    const exam = useGetExamById(examID);
    const examSet = useGetExamSetByExamId(examID);
    let activeExamSet = { examSetName: '', examType: '', examSetID: '' };
    if (examSet) {
        activeExamSet = examSet[Number(setID) - 1];
    }
    const questions = useQuestionsByExamSetId(activeExamSet.examSetID);
    const { noOfQuestionsPerSet, examName } = exam;
    if (!noOfQuestionsPerSet)
        throw Error('Question not found');
    return (examSet.length && (React.createElement("div", { className: classes.examFormBox, key: setID, id: "examForm" },
        React.createElement(ExamSetHeader, { examName: `${examName} > ${activeExamSet.examSetName}` }),
        activeExamSet.examType === 'LEARNING' ? (React.createElement(React.Fragment, null,
            React.createElement(QuestionExcelButton, { exam: exam, examSet: activeExamSet, questions: questions }),
            React.createElement(ExamLearning, { exam: exam, examSet: activeExamSet }))) : (React.createElement(React.Fragment, null,
            React.createElement(QuestionExcelButton, { exam: exam, examSet: activeExamSet, questions: questions }),
            React.createElement(ExamQuestionTab, { exam: exam, examSet: activeExamSet }))))));
};
export default ExamSetQuestion;
