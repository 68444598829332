var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Close } from '@mui/icons-material';
import { Box, IconButton, Modal, Typography } from '@mui/material';
import React from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as XLSX from 'xlsx';
import { getQuestionPageLoad, postTopLoaderFalse, postTopLoaderTrue, saveQuestionDetails, setSnackbar, } from '../../store/actions';
import { useStyle } from '../../styles/style';
import MCQQuestionTemplateButton from './MCQQuestionTemplateButton';
const fileTypes = ['xlsx', 'csv', 'xls'];
const sheetHeaders = [
    'sr_no',
    'question',
    'choice_1',
    'choice_2',
    'choice_3',
    'choice_4',
    'choice_5',
    'correct_choice',
    'question_hint',
];
const QuestionExcelModal = ({ open, handleClose, exam, examSet, questions, }) => {
    const dispatch = useDispatch();
    const { setID } = useParams();
    const classes = useStyle();
    const user = useSelector((state) => state.auth.user);
    const handleDataApiCall = (data) => __awaiter(void 0, void 0, void 0, function* () {
        const commonPayload = {
            activeStatus: true,
            createdBy: user.emailId,
            modifiedBy: user.emailId,
        };
        const payload = Object.assign(Object.assign({ questionDescription: data.question, correctMark: exam.correctAnswerMark, negativeMark: exam.negativeAnswerMark, questionHint: data.question_hint }, commonPayload), { examSetID: examSet.examSetID, choices: data.choices });
        dispatch(saveQuestionDetails(payload));
    });
    const handleFileUpload = (e) => {
        const file = e;
        const reader = new FileReader();
        dispatch(postTopLoaderTrue());
        dispatch(setSnackbar({
            snackbarOpen: true,
            snackbarType: 'info',
            snackbarMsg: 'Validating your uploaded Sheet.',
        }));
        reader.onload = (event) => {
            const workbook = XLSX.read(event.target.result, { type: 'binary' });
            const sheetName = workbook.SheetNames[0];
            if (sheetName !== 'MCQ_QUESTION_TEMPLATE') {
                dispatch(postTopLoaderFalse());
                dispatch(setSnackbar({
                    snackbarOpen: true,
                    snackbarType: 'error',
                    snackbarMsg: 'Sheet name does not match the template constraint.Please download again and upload.',
                }));
                return;
            }
            const sheet = workbook.Sheets[sheetName];
            const sheetData = XLSX.utils.sheet_to_json(sheet, {
                header: 1,
                range: 2,
            });
            if (sheetData.length - 1 > exam.noOfQuestionsPerSet - questions.length) {
                dispatch(postTopLoaderFalse());
                dispatch(setSnackbar({
                    snackbarOpen: true,
                    snackbarType: 'error',
                    snackbarMsg: `Data is more than expected. ${exam.noOfQuestionsPerSet - questions.length} questions are expected. But you are trying to upload ${sheetData.length - 1} questions.`,
                }));
                return;
            }
            if (sheetData.length < 2) {
                dispatch(postTopLoaderFalse());
                dispatch(setSnackbar({
                    snackbarOpen: true,
                    snackbarType: 'error',
                    snackbarMsg: 'No data found. Please enter the data and upload the file.',
                }));
                return;
            }
            if (sheetData[0].length !== 9) {
                dispatch(postTopLoaderFalse());
                dispatch(setSnackbar({
                    snackbarOpen: true,
                    snackbarType: 'error',
                    snackbarMsg: 'Sheet Headers does not match the template constraints.Please download again and upload.',
                }));
                return;
            }
            for (let i = 1; i < sheetData.length; i++) {
                if (sheetData[i][7] === null || sheetData[i][7] === undefined) {
                    dispatch(postTopLoaderFalse());
                    dispatch(setSnackbar({
                        snackbarOpen: true,
                        snackbarType: 'error',
                        snackbarMsg: `Question ${i} does not have any correct choice please fill it and upload the file again.`,
                    }));
                    return;
                }
                const commonPayload = {
                    activeStatus: true,
                    createdBy: user.emailId,
                    modifiedBy: user.emailId,
                };
                const data = { choices: [] };
                sheetData[i].forEach((d, index) => {
                    var _a;
                    if (sheetHeaders[index].startsWith('choice_') && d) {
                        console.log(d);
                        data.choices = [
                            ...((_a = data.choices) !== null && _a !== void 0 ? _a : []),
                            Object.assign(Object.assign({}, commonPayload), { isCorrect: sheetData[i][7] === sheetData[0][index], choice: String(d) }),
                        ];
                        return;
                    }
                    if (index === 7 || index === 0) {
                        return;
                    }
                    data[sheetHeaders[index]] = d;
                });
                console.log(data);
                handleDataApiCall(data);
                handleClose();
                dispatch(getQuestionPageLoad({ params: { examID: exam.examID, setID } }));
            }
        };
        reader.readAsBinaryString(file);
    };
    return (React.createElement(Modal, { open: open, onClose: handleClose, disableScrollLock: true },
        React.createElement(Box, null,
            React.createElement("div", { className: classes.questionExcel },
                React.createElement("div", { className: classes.questionPreviewMain },
                    React.createElement("div", { className: classes.questionExcelHeader },
                        React.createElement(Typography, { variant: "h3" }, "Upload Question Template"),
                        React.createElement("div", { className: classes.closeIcon },
                            React.createElement(IconButton, { onClick: handleClose },
                                React.createElement(Close, null)))),
                    React.createElement("div", { className: classes.questionExcelBody },
                        React.createElement(Typography, { fontFamily: "Poppins", variant: "body2" }, "To Upload Questions using Excel. Please download the below template file."),
                        React.createElement(MCQQuestionTemplateButton, { examSetType: "Question" }),
                        React.createElement(Typography, { fontFamily: "Poppins", variant: "body2" }, "Upload the excel file once completed."),
                        React.createElement(FileUploader, { files: fileTypes, handleChange: handleFileUpload })))))));
};
export default QuestionExcelModal;
