/* eslint-disable @typescript-eslint/no-explicit-any */
import download from 'downloadjs';
import Cookies from 'js-cookie';
import moment from 'moment';
import { all, call, put, select, spawn, takeEvery, takeLatest, takeLeading, } from 'redux-saga/effects';
import { service } from './services/service';
import * as Action from './store/actions';
import { GET_TENANT_CODE, POST_TEACHER_LOGIN, POST_TEACHER_SIGN_UP, POST_TENANT_FORGOT_PASSWORD, POST_TENANT_FORGOT_PASSWORD_VERIFY, } from './store/authConstants';
import { GET_ALL_EXAMS, GET_ALL_EXAM_SET_DETAILS, GET_BILLING_REPORT, GET_DASHBOARD_REPORT, GET_EXAM_BY_ID, GET_EXAM_REPORT, GET_QUESTIONS_BY_EXAMSET, GET_QUESTIONS_ON_PAGE_LOAD, GET_STUDENTS_IN_EXAM, GET_STUDENT_EXAM_MAPPING, GET_STUDENT_EXAM_RESULT_ANALYSIS, GET_STUDENT_TENANT_MAPPING, POST_CONTACT_US_DETAIL, POST_DOWNLOAD_QUESTION_TEMPLATE, POST_EXAM_PUBLISH, POST_PAGE_AUDIT, POST_SEARCH_EXAM, POST_STUDENT_EXAM_MAPPING, POST_STUDENT_STATUS, POST_UPDATE_EXAM, POST_UPDATE_EXAMSET, POST_UPDATE_QUESTION, SAVE_EXAM_DETAILS, SAVE_EXAM_SET_DETAILS, SAVE_QUESTION_DETAILS, } from './store/constants';
import { getCompressedData } from './utils/compressionUtils';
import { stringToBoolean } from './utils/commonUtils';
function* getExamById(action) {
    var _a;
    const exams = yield select((state) => state.exam.exams);
    return (_a = exams.filter((exam) => exam.examID === action.payload)) === null || _a === void 0 ? void 0 : _a[0];
}
function* getExamSetByExamId(action) {
    const examSets = yield select((state) => state.exam.examSet);
    return examSets === null || examSets === void 0 ? void 0 : examSets[action.examID];
}
function* getQuestionByExamSetId(action) {
    const questions = yield select((state) => state.exam.questions);
    return questions === null || questions === void 0 ? void 0 : questions[action.examSetID];
}
function* getAllExams() {
    try {
        const exams = yield select((state) => state.exam.exams);
        if (exams.length) {
            yield put(Action.updateLoader({ loader: 'examListLoader', value: false }));
            return;
        }
        const res = yield call(service.getAllExams);
        yield put(Action.getAllExamsSuccess(res));
    }
    catch (e) {
        console.log(e);
    }
}
function* saveExamDetails(action) {
    var _a;
    try {
        const { exam, navigate, type } = action.payload;
        const user = yield select((state) => state.auth.user);
        const payload = Object.assign(Object.assign({}, exam), { activeStatus: true, createdBy: user.emailId, modifiedBy: user.emailId, tenantID: user.tenantID });
        const res = yield call(service.saveExamDetails, payload);
        yield put(Action.saveExamDetailsSuccess(res));
        if (type === 'next') {
            navigate(`/exams/${res.examID}/sets`);
        }
        else if (type === 'submit') {
            navigate(`/exams/${res.examID}/view`);
        }
        yield put(Action.setSnackbar({
            snackbarOpen: true,
            snackbarType: 'success',
            snackbarMsg: 'Exam saved successfully',
        }));
    }
    catch (e) {
        yield put(Action.saveExamDetailsFailure());
        yield put(Action.setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMsg: (_a = e.data.message) !== null && _a !== void 0 ? _a : 'Error while saving the exam',
        }));
    }
}
function* saveExamSetDetails(action) {
    try {
        const { data, navigate, type, examID } = action.payload;
        const user = yield select((state) => state.auth.user);
        const callToBeMade = yield data.sets.map((set) => call(service.saveExamSetDetails, {
            examSetName: set.examSetName,
            examID,
            examType: set.examType,
            isFree: stringToBoolean(set.isFree),
            activeStatus: true,
            createdBy: user.emailId,
            modifiedBy: user.emailId,
        }));
        const res = yield all(callToBeMade);
        yield put(Action.saveExamSetDetailsSuccess({ res, examID }));
        if (type === 'submit') {
            navigate(`/exams/${examID}/sets`);
        }
        else if (type === 'next') {
            navigate(`/${examID}/sets/1`);
        }
        yield put(Action.setSnackbar({
            snackbarOpen: true,
            snackbarType: 'success',
            snackbarMsg: 'Exam sets saved successfully.',
        }));
    }
    catch (e) {
        yield put(Action.setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMsg: 'Error while saving the sets.',
        }));
    }
}
function* getAllExamSetDetails(action) {
    try {
        const { examID } = action.payload.params;
        const examSet = yield call(getExamSetByExamId, { examID });
        if (examSet) {
            yield put(Action.saveExamSetDetailsSuccess({ res: examSet, examID }));
            return;
        }
        const res = yield call(service.getAllExamSetDetails, { examID });
        // if (!res?.length) navigate(`/exams/${examID}/sets`);
        yield put(Action.saveExamSetDetailsSuccess({ res, examID }));
    }
    catch (e) {
        yield put(Action.setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMsg: 'Error getting exam set details',
        }));
    }
}
function* saveQuestionDetails(action) {
    try {
        let res = yield call(service.saveQuestionDetails, action.payload);
        res = Object.assign(Object.assign({}, res), { choices: action.payload.choices });
        yield put(Action.saveQuestionDetailsSuccess(res));
        yield put(Action.setSnackbar({
            snackbarOpen: true,
            snackbarType: 'success',
            snackbarMsg: 'Question saved successfully.',
        }));
    }
    catch (e) {
        yield put(Action.setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMsg: 'Error in saving question.',
        }));
    }
}
function* getQuestionsByExamSet(action) {
    try {
        const { examID, setID } = action.payload.params;
        const user = yield select((state) => state.auth.user);
        const examSet = yield call(getExamSetByExamId, { examID });
        if (!examSet.length) {
            yield put(Action.updateLoader({ loader: 'examQuestionLoader', value: false }));
            return;
        }
        const { examSetID } = examSet[Number(setID) - 1];
        const questions = yield call(getQuestionByExamSetId, { examSetID });
        if (questions === null || questions === void 0 ? void 0 : questions.length) {
            yield put(Action.updateLoader({
                loader: 'examQuestionLoader',
                value: false,
            }));
            return;
        }
        const { tenantID } = user;
        const res = yield call(service.getQuestionsByExamSet, {
            examSetID,
            tenantID,
        });
        yield put(Action.getQuestionsByExamSetSuccess({ res, examSetID }));
    }
    catch (e) {
        console.log(e);
    }
}
function* getQuestionPageLoad(action) {
    const { examID, setID } = action.payload.params;
    const examSets = yield call(getExamSetByExamId, { examID });
    const examSet = examSets === null || examSets === void 0 ? void 0 : examSets[setID - 1];
    if (!(examSet === null || examSet === void 0 ? void 0 : examSet.length))
        yield call(getAllExamSetDetails, action);
    yield call(getQuestionsByExamSet, action);
}
function* postExamPublish(action) {
    try {
        const { examID } = action.payload;
        yield put(Action.setSnackbar({
            snackbarOpen: true,
            snackbarType: 'info',
            snackbarMsg: 'Exam Publish In Progress',
        }));
        yield call(service.postExamPublish, { examID });
        yield put(Action.postExamPublishSuccess({ examID }));
        yield put(Action.setSnackbar({
            snackbarOpen: true,
            snackbarType: 'success',
            snackbarMsg: 'Exam Published Successfully',
        }));
    }
    catch (e) {
        yield put(Action.setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMsg: 'Exam Publish Failed',
        }));
    }
}
function* postUpdateExam(action) {
    try {
        const user = yield select((state) => state.auth.user);
        const { exam } = action.payload;
        const payload = Object.assign(Object.assign({}, exam), { activeStatus: true, createdBy: user.emailId, modifiedBy: user.emailId });
        const res = yield call(service.postUpdateExam, payload);
        yield put(Action.postUpdateExamSuccess(res));
        yield put(Action.setSnackbar({
            snackbarOpen: true,
            snackbarType: 'success',
            snackbarMsg: 'Exam Updated Successfully.',
        }));
    }
    catch (e) {
        yield put(Action.setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMsg: 'Exam Updation Failed',
        }));
    }
}
function* postSearchExam(action) {
    try {
        const user = yield select((state) => state.auth.user);
        const res = yield call(service.postExamSearchRegistry, {
            searchText: action.payload.search,
            sourceIP: '',
            tenantID: user.tenantID,
        });
        yield put(Action.postSearchExamSuccess(res));
    }
    catch (e) {
        console.log(e);
    }
}
function* postUpdateExamSet(action) {
    try {
        const { data, navigate, type, examID } = action.payload;
        const user = yield select((state) => state.auth.user);
        const callToBeMade = yield data.sets.map((set) => call(service.postUpdateExamSet, {
            examID: action.payload.examID,
            examSetID: set.examSetID,
            data: Object.assign(Object.assign({}, set), { isFree: stringToBoolean(set.isFree), examID, createdBy: user.emailId, modifiedBy: user.emailId }),
        }));
        const res = yield all(callToBeMade);
        yield put(Action.saveExamSetDetailsSuccess({ res, examID }));
        if (type === 'submit') {
            navigate(`/exams/${examID}/sets`);
        }
        else if (type === 'next') {
            navigate(`/${examID}/sets/1`);
        }
        yield put(Action.setSnackbar({
            snackbarOpen: true,
            snackbarType: 'success',
            snackbarMsg: 'Exam sets updated successfully.',
        }));
    }
    catch (e) {
        yield put(Action.setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMsg: 'Exam sets saving failed.',
        }));
    }
}
function* getExamReport() {
    try {
        yield spawn(getAllExams);
        const user = yield select((state) => state.auth.user);
        const res = yield call(service.getExamReport, { tenantID: user.tenantID });
        yield put(Action.getExamReportSuccess(res));
    }
    catch (e) {
        yield put(Action.setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMsg: 'Error occurred while fetching report ',
        }));
        yield put(Action.getExamReportFailure());
    }
}
function* getDashboardReport() {
    try {
        const user = yield select((state) => state.auth.user);
        if (!(user === null || user === void 0 ? void 0 : user.tenantID))
            return;
        const res = yield call(service.getDashboardReport, {
            tenantID: user.tenantID,
        });
        yield put(Action.getDashboardReportSuccess(res));
    }
    catch (e) {
        yield put(Action.setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMsg: 'Error occurred while fetching report ',
        }));
        yield put(Action.getDashboardReportFailure());
    }
}
function* getBillingReport() {
    try {
        yield spawn(getAllExams);
        const user = yield select((state) => state.auth.user);
        const res = yield call(service.getBillingReport, {
            tenantID: user.tenantID,
        });
        yield put(Action.getBillingReportSuccess(res));
    }
    catch (e) {
        yield put(Action.setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMsg: 'Error occurred while fetching report ',
        }));
        yield put(Action.getBillingReportFailure());
    }
}
function* getStudentsInExam(action) {
    try {
        const { examID } = action.payload.params;
        const res = yield call(service.getExamStudents, {
            examID,
        });
        yield put(Action.getStudentsInExamSuccess({ examID, data: res }));
    }
    catch (e) {
        yield put(Action.setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMsg: 'Error occurred while fetching report ',
        }));
        yield put(Action.getStudentsInExamFailure());
    }
}
function* postStudentExamResultAnalysis(action) {
    var _a;
    try {
        const { examID, studentID } = action.payload.params;
        const res = yield call(service.postStudentExamResultAnalysis, {
            examID,
            data: { studentID },
        });
        yield put(Action.getStudentExamResultAnalysisSuccess({ examID, data: res }));
    }
    catch (e) {
        yield put(Action.setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMsg: (_a = e.data.message) !== null && _a !== void 0 ? _a : 'Error occurred',
        }));
    }
}
function* postUpdateQuestion(action) {
    try {
        const { data, examSetID, questionIndex } = action.payload;
        const questions = yield call(getQuestionByExamSetId, { examSetID });
        const question = questions[questionIndex];
        const { choices } = question;
        const choice = data.choices.map((c, index) => {
            const prevChoice = choices[index];
            return Object.assign(Object.assign({}, c), { choiceID: prevChoice === null || prevChoice === void 0 ? void 0 : prevChoice.choiceID });
        });
        data.choices = choice;
        const payload = {
            data,
            questionID: question.questionID,
        };
        const res = yield call(service.postUpdateQuestion, payload);
        yield put(Action.postUpdateQuestionSuccess({ res, examSetID, questionIndex }));
        yield put(Action.setSnackbar({
            snackbarOpen: true,
            snackbarType: 'success',
            snackbarMsg: 'Exam Updated Successfully.',
        }));
    }
    catch (e) {
        console.log(e);
        yield put(Action.postUpdateQuestionFailure());
        yield put(Action.setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMsg: 'Error while updating questions.',
        }));
    }
}
function* postContactUsDetail(action) {
    var _a, _b;
    try {
        const res = yield call(service.postContactUsDetail, Object.assign({}, action.payload));
        yield put(Action.setSnackbar({
            snackbarOpen: true,
            snackbarType: 'success',
            snackbarMsg: res.message,
        }));
    }
    catch (e) {
        yield put(Action.setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMsg: (_b = (_a = e === null || e === void 0 ? void 0 : e.data) === null || _a === void 0 ? void 0 : _a.message) !== null && _b !== void 0 ? _b : 'Error occurred',
        }));
    }
}
function* postTeacherLogin(action) {
    var _a, _b;
    try {
        const res = yield call(service.postTeacherLogin, Object.assign(Object.assign({}, action.payload), { loginIP: '' }));
        yield put(Action.postTeacherLoginSuccess({ res }));
        Cookies.set('user', getCompressedData(JSON.stringify(res)), {
            expires: 0.5,
        });
        yield put(Action.setSnackbar({
            snackbarOpen: true,
            snackbarType: 'success',
            snackbarMsg: 'Logged In Successfully.',
        }));
        window.location.reload();
    }
    catch (e) {
        yield put(Action.setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMsg: (_b = (_a = e === null || e === void 0 ? void 0 : e.data) === null || _a === void 0 ? void 0 : _a.message) !== null && _b !== void 0 ? _b : 'Error while Logging In',
        }));
    }
}
function* postTeacherSignUp(action) {
    var _a, _b;
    try {
        const { emailId, password } = action.payload;
        yield call(service.postTeacherSignUp, action.payload);
        const res = yield call(service.postTeacherLogin, {
            loginID: emailId,
            password,
            loginIP: '',
        });
        yield put(Action.postTeacherLoginSuccess({ res }));
        Cookies.set('user', getCompressedData(JSON.stringify(res)), {
            expires: 0.5,
        });
        yield put(Action.setSnackbar({
            snackbarOpen: true,
            snackbarType: 'success',
            snackbarMsg: 'Signed up Successfully.',
        }));
        yield put(Action.postTeacherSignUpSuccess());
        window.location.reload();
    }
    catch (e) {
        yield put(Action.setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMsg: (_b = (_a = e === null || e === void 0 ? void 0 : e.data) === null || _a === void 0 ? void 0 : _a.message) !== null && _b !== void 0 ? _b : 'Error occurred while Sign Up.Please contact support.',
        }));
    }
}
function* postPageAudit(action) {
    try {
        const sessionID = Cookies.get('sessionID');
        const user = yield select((state) => state.auth.user);
        console.log(moment().format('YYYY-MM-DD HH:mm:ss:SSS Z'));
        yield spawn(service.postPageAudit, {
            sessionID,
            dateTime: moment().format('YYYY-MM-DD HH:mm:ss:SSS Z'),
            page: action.payload.page,
            IP: '',
            createdBy: user.loggedIn ? user === null || user === void 0 ? void 0 : user.emailId : 'anonymous',
        });
    }
    catch (e) {
        console.log('PageAudit Error');
    }
}
function* postTenantForgotPassword(action) {
    var _a;
    try {
        const res = yield call(service.postTenantForgotPassword, Object.assign({}, action.payload));
        yield put(Action.forgotPasswordModalClose());
        yield put(Action.setSnackbar({
            snackbarOpen: true,
            snackbarType: 'success',
            snackbarMsg: res.message,
        }));
    }
    catch (e) {
        yield put(Action.setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMsg: (_a = e.data.message) !== null && _a !== void 0 ? _a : 'Error occurred',
        }));
    }
}
function* postTenantForgotPasswordVerify(action) {
    var _a, _b;
    try {
        const { requestId, password, navigate } = action.payload;
        const res = yield call(service.postTenantForgotPasswordVerify, {
            requestId,
            password,
        });
        navigate('/', { replace: true });
        yield put(Action.setSnackbar({
            snackbarOpen: true,
            snackbarType: 'success',
            snackbarMsg: res.message,
        }));
    }
    catch (e) {
        yield put(Action.setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMsg: (_b = (_a = e === null || e === void 0 ? void 0 : e.data) === null || _a === void 0 ? void 0 : _a.message) !== null && _b !== void 0 ? _b : 'Error occurred',
        }));
    }
}
function* postDownloadQuestionTemplate(action) {
    var _a, _b;
    try {
        const res = yield call(service.postDownloadQuestionTemplate, action.payload);
        download(res, 'Question_Template.xlsx');
    }
    catch (e) {
        yield put(Action.setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMsg: (_b = (_a = e === null || e === void 0 ? void 0 : e.data) === null || _a === void 0 ? void 0 : _a.message) !== null && _b !== void 0 ? _b : 'Error while downloading file.',
        }));
    }
}
function* getStudentTenantMapping() {
    var _a, _b;
    try {
        const user = yield select((state) => state.auth.user);
        const res = yield call(service.getStudentTenantMapping, {
            tenantID: user.tenantID,
        });
        yield put(Action.getStudentTenantMappingSuccess(res));
    }
    catch (e) {
        yield put(Action.getStudentTenantMappingFailure());
        yield put(Action.setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMsg: (_b = (_a = e === null || e === void 0 ? void 0 : e.data) === null || _a === void 0 ? void 0 : _a.message) !== null && _b !== void 0 ? _b : 'Error while fetching data.',
        }));
    }
}
function* getStudentExamMapping() {
    var _a, _b;
    try {
        const user = yield select((state) => state.auth.user);
        const res = yield call(service.getStudentExamMapping, {
            tenantID: user.tenantID,
        });
        yield put(Action.getStudentExamMappingSuccess(res));
    }
    catch (e) {
        yield put(Action.getStudentExamMappingFailure());
        yield put(Action.setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMsg: (_b = (_a = e === null || e === void 0 ? void 0 : e.data) === null || _a === void 0 ? void 0 : _a.message) !== null && _b !== void 0 ? _b : 'Error while fetching data.',
        }));
    }
}
function* postStudentExamMapping(action) {
    var _a, _b;
    try {
        const user = yield select((state) => state.auth.user);
        const res = yield call(service.postStudentExamMapping, Object.assign({ tenantID: user.tenantID }, action.payload));
        yield put(Action.postStudentExamMappingSuccess(res));
        yield put(Action.setSnackbar({
            snackbarOpen: true,
            snackbarType: 'success',
            snackbarMsg: 'Student Exam Mapping Successful.',
        }));
        window.location.reload();
    }
    catch (e) {
        yield put(Action.postStudentExamMappingFailure());
        yield put(Action.setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMsg: (_b = (_a = e === null || e === void 0 ? void 0 : e.data) === null || _a === void 0 ? void 0 : _a.message) !== null && _b !== void 0 ? _b : 'Error while saving data.',
        }));
    }
}
function* postStudentStatus(action) {
    var _a, _b;
    try {
        const res = yield call(service.postStudentStatus, Object.assign({}, action.payload));
        yield put(Action.setSnackbar({
            snackbarOpen: true,
            snackbarType: 'success',
            snackbarMsg: 'Student Approved Successfully.',
        }));
        yield call(getStudentTenantMapping);
    }
    catch (e) {
        yield put(Action.setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMsg: (_b = (_a = e === null || e === void 0 ? void 0 : e.data) === null || _a === void 0 ? void 0 : _a.message) !== null && _b !== void 0 ? _b : 'Error while fetching data.',
        }));
    }
}
function* getTenantCode() {
    try {
        const user = yield select((state) => state.auth.user);
        const res = yield call(service.getTenantCode, {
            tenantID: user.tenantID,
        });
        navigator.clipboard.writeText(`${process.env.REACT_APP_STUDENT_PORTAL_URL}REG${res.tenantCode}`);
        yield put(Action.setSnackbar({
            snackbarOpen: true,
            snackbarType: 'success',
            snackbarMsg: 'Link copied successfully.',
        }));
        yield put(Action.getTenantCodeSuccess(res));
    }
    catch (e) { }
}
function* saga() {
    yield takeLatest(GET_ALL_EXAMS, getAllExams);
    yield takeLatest(SAVE_EXAM_DETAILS, saveExamDetails);
    yield takeEvery(GET_EXAM_BY_ID, getExamById);
    yield takeLatest(SAVE_EXAM_SET_DETAILS, saveExamSetDetails);
    yield takeLatest(GET_ALL_EXAM_SET_DETAILS, getAllExamSetDetails);
    yield takeLatest(SAVE_QUESTION_DETAILS, saveQuestionDetails);
    yield takeLatest(GET_QUESTIONS_BY_EXAMSET, getQuestionsByExamSet);
    yield takeLatest(GET_QUESTIONS_ON_PAGE_LOAD, getQuestionPageLoad);
    yield takeLatest(POST_EXAM_PUBLISH, postExamPublish);
    yield takeLatest(POST_UPDATE_EXAM, postUpdateExam);
    yield takeEvery(POST_SEARCH_EXAM, postSearchExam);
    yield takeLatest(POST_UPDATE_EXAMSET, postUpdateExamSet);
    yield takeLatest(GET_EXAM_REPORT, getExamReport);
    yield takeLatest(GET_DASHBOARD_REPORT, getDashboardReport);
    yield takeLatest(GET_BILLING_REPORT, getBillingReport);
    yield takeLatest(GET_STUDENTS_IN_EXAM, getStudentsInExam);
    yield takeLatest(GET_STUDENT_EXAM_RESULT_ANALYSIS, postStudentExamResultAnalysis);
    yield takeLatest(POST_UPDATE_QUESTION, postUpdateQuestion);
    yield takeLatest(POST_CONTACT_US_DETAIL, postContactUsDetail);
    yield takeLatest(POST_TEACHER_LOGIN, postTeacherLogin);
    yield takeLatest(POST_TEACHER_SIGN_UP, postTeacherSignUp);
    yield takeEvery(POST_PAGE_AUDIT, postPageAudit);
    yield takeLatest(POST_TENANT_FORGOT_PASSWORD, postTenantForgotPassword);
    yield takeLeading(POST_TENANT_FORGOT_PASSWORD_VERIFY, postTenantForgotPasswordVerify);
    yield takeLeading(POST_DOWNLOAD_QUESTION_TEMPLATE, postDownloadQuestionTemplate);
    yield takeLatest(GET_STUDENT_TENANT_MAPPING, getStudentTenantMapping);
    yield takeLatest(GET_STUDENT_EXAM_MAPPING, getStudentExamMapping);
    yield takeLatest(POST_STUDENT_EXAM_MAPPING, postStudentExamMapping);
    yield takeLatest(POST_STUDENT_STATUS, postStudentStatus);
    yield takeLatest(GET_TENANT_CODE, getTenantCode);
}
export default saga;
