/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-template-curly-in-string */
import { clsx } from 'clsx';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import * as Actions from '../../store/actions';
import { useStyle } from '../../styles/style';
import { useGetExamSetByExamId } from '../../utils/hookUtils';
import Chips from '../BaseComponents/Chips';
const kababOptions = {
    Questions: '/${props.examID}/sets/${props.setID}',
};
const ExamSetList = () => {
    const classes = useStyle();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { examID, setID } = useParams();
    if (!examID)
        throw Error('ExamID not found');
    const examSet = useGetExamSetByExamId(examID);
    useEffect(() => {
        if (!examSet.length) {
            navigate(`/exams/${examID}/sets`);
            dispatch(Actions.setSnackbar({
                snackbarOpen: true,
                snackbarType: 'warning',
                snackbarMsg: 'Sets not created.Please create sets first.',
            }));
        }
    }, [examSet]);
    return (examSet.length && (React.createElement("div", { className: clsx(classes.examList, classes.scrollHidden) }, examSet.map((set, i) => (React.createElement(Chips, { isLearning: set.examType === 'LEARNING', label: set.examSetName, key: set.examSetID, examID: examID, setID: i + 1, to: "/${props.examID}/sets/${props.setID}", classes: {
            chip: classes.chip,
            chipBottom: classes.chipBottom,
            chipTopRight: classes.chipTopRight,
            moreVertIcon: classes.moreVertIcon,
            chipTitle: classes.chipTitle,
            chipRightBottom: classes.chipRightBottom,
            publish: classes.publish,
            navLink: classes.navLink,
            learningTypo: classes.learningTypo,
            chipMain: setID === String(i + 1)
                ? clsx(classes.chipMain, classes.chipHighlight)
                : classes.chipMain,
        }, kababOptions: kababOptions }))))));
};
export default ExamSetList;
