var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { z } from 'zod';
import * as Actions from '../../store/actions';
import { useStyle } from '../../styles/style';
import EditorCK from '../BaseComponents/EditorCK';
const ExamLearningQuestion = (props) => {
    const { defaultValues, examSet, access, index } = props;
    const [disabled, setDisabled] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const classes = useStyle();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);
    const questionFormValidation = z.object({
        questionDescription: z
            .string()
            .nonempty('Question description is Required'),
        choices: z
            .object({
            choice: z.string().nonempty(' Choice is Required'),
        })
            .array()
            .min(1),
    });
    const questionForm = useForm({
        defaultValues,
        resolver: zodResolver(questionFormValidation),
        mode: 'onChange',
        values: defaultValues,
    });
    const { handleSubmit, formState: { errors }, control, } = questionForm;
    useEffect(() => {
        var _a;
        if ((_a = defaultValues.questionDescription) === null || _a === void 0 ? void 0 : _a.length)
            setIsUpdate(true);
        if (access)
            setDisabled(true);
    }, [defaultValues, access]);
    // useEffect(() => {
    //   const selectedChoice = fields.findIndex((f) => f.isCorrect);
    //   console.log(selectedChoice);
    //   if (selectedChoice) setValue('correctChoice', selectedChoice.toString());
    // }, [defaultValues, fields]);
    const handleQuestionSubmit = (data) => {
        const commonPayload = Object.assign(Object.assign({ activeStatus: true }, (!isUpdate && { createdBy: user.emailId })), { modifiedBy: user.emailId });
        const newData = __rest(data, []);
        const payload = Object.assign(Object.assign(Object.assign({}, newData), commonPayload), { examSetID: examSet.examSetID, choices: newData.choices.map((choice, index) => (Object.assign(Object.assign({}, choice), commonPayload))) });
        if (isUpdate)
            dispatch(Actions.postUpdateQuestion({
                data: payload,
                examSetID: examSet.examSetID,
                questionIndex: index - 1,
            }));
        else {
            dispatch(Actions.saveQuestionDetails(payload));
            setIsUpdate(true);
        }
    };
    // console.log(questionForm.getValues());
    return (React.createElement("div", { className: classes.questionForm },
        React.createElement(Controller, { control: control, name: "questionDescription", render: ({ field: { onChange, value } }) => (React.createElement(EditorCK, { onChange: onChange, errors: errors.questionDescription, value: value, disabled: disabled, placeholder: "Question" })) }),
        React.createElement(Controller, { control: control, name: "choices.0.choice", render: ({ field: { onChange, value } }) => {
                var _a, _b;
                return (React.createElement(EditorCK, { onChange: onChange, errors: (_b = (_a = errors.choices) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.choice, value: value, disabled: disabled, placeholder: "Answer" }));
            } }),
        React.createElement("div", { className: classes.examFormButton },
            React.createElement(Button, { variant: "contained", disableElevation: true, color: "primary", type: "submit", onClick: handleSubmit(handleQuestionSubmit), disabled: disabled }, isUpdate ? 'Update' : 'Submit'))));
};
export default ExamLearningQuestion;
